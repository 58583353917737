<template>
  <croppa
    v-model="mycroppa"
    @file-type-mismatch="onFileTypeMismatch"
    @file-choose="handleImageChanged"
    @move="handleImageChanged"
    @zoom="handleImageChanged"
    @image-remove="handleImageChanged"
    :width="width"
    :height="height"
    :quality="quality"
    :prevent-white-space="true"
    :placeholder="placeholder"
    :placeholder-font-size="placeholderFontSize"
    :initial-image="customControls ? '' : imageUrlProxy"
    :show-remove-button="showRemoveButton"
    :disabled="disabled"
    :accept="accept"
    :disable-drag-to-move="disableCropping"
    :disable-scroll-to-zoom="disableCropping"
    :disable-pinch-to-zoom="disableCropping"
    :style="{ display: customControls ? 'none' : '' }"
    ref="mycroppa"
    remove-button-color="black"
  >
  </croppa>
</template>

<script>
import { mapMutations } from 'vuex';
import FileHelperModule from '@/helpers/file-helper-module';

export default {
  props: {
    imageUrl: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: 300,
    },
    height: {
      type: Number,
      default: 210,
    },
    quality: {
      type: Number,
      default: 1,
    },
    placeholder: {
      type: String,
      default: 'Upload image',
    },
    placeholderFontSize: {
      type: Number,
      default: 12,
    },
    onChange: {
      type: Function,
    },
    onError: {
      type: Function,
    },
    s3Folder: {
      type: String,
      default: '',
    },
    s3SubDir: { type: String, default: '', required: false },
    showRemoveButton: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    imageTypeSaved: {
      type: String,
      default: 'png',
      validator(type) {
        return ['png', 'jpeg'].includes(type);
      },
    },
    uploadConfig: { type: Object, required: false },
    storeOriginal: { type: Boolean, default: false, required: false },
    accept: { type: String, default: '', required: false },
    disableCropping: { type: Boolean, default: false, required: false },
    showPreviews: { type: Boolean, default: false, required: false },
    customControls: { type: Boolean, default: false, required: false },
  },
  data: () => ({
    mycroppa: {},
    isAltered: false,
    imageDataList: [],
  }),
  watch: {
    imageUrl(newValue = '') {
      if (
        this.mycroppa.refresh &&
        (!this.isAltered || !newValue) &&
        (this.showPreviews || newValue.includes('http'))
      ) {
        this.mycroppa.refresh();
      }
    },
    originalImage() {
      if (this?.mycroppa?.originalImage && this.isAltered && this.showPreviews) {
        this.prepareImagesForStorage();
      }
    },
  },
  methods: {
    ...mapMutations('file', ['setPreviews']),
    ...FileHelperModule,
    onFileTypeMismatch(...args) {
      if (typeof this.onError === 'function') {
        return this.onError(...args);
      }
      return this.$toast('Invalid file type, choose a jpg or png file.');
    },
    handleImageChanged(...args) {
      this.isAltered = true;
      const temp_name = (this.mycroppa.getChosenFile() && this.mycroppa.getChosenFile().name) || '';
      this.$emit('update:imageUrl', temp_name);
      if (typeof this.onChange === 'function') {
        return this.onChange(...args);
      }
      return true;
    },
    handleCustomRemoveImage() {
      this.isAltered = true;
      if (this.mycroppa?.originalImage) this.mycroppa.originalImage = undefined;
      this.$emit('update:imageUrl', { delete: true, isPreview: true });
    },
    async save() {
      if (!this.isAltered) return;
      /* handle delete image */
      if (!this.originalImage) {
        this.$emit('update:imageUrl', this.uploadConfig ? { delete: true } : '');
        return;
      }
      if (!this.showPreviews && this.uploadConfig) {
        this.prepareImagesForStorage();
      }
      await this.handleImageStorage();
      this.isAltered = false;
    },
    prepareImagesForStorage() {
      const previews = {};
      const imageDataList = Object.entries(this.uploadConfig).map(([imageKey, imageDimensions]) => {
        const canvas = this.createCanvas(
          this.originalImage,
          imageDimensions.width,
          imageDimensions.height,
          true,
        ).toDataURL(this.imageType);
        if (this.showPreviews) {
          previews[imageKey] = canvas;
        }
        return {
          imageSubDir: imageKey,
          cleanedDataURL: this.cleanDataURL(canvas),
        };
      });
      if (this.storeOriginal) {
        const originalCanvas = this.createCanvas(
          this.originalImage,
          this.originalImage.width,
          this.originalImage.height,
        ).toDataURL(this.imageType);
        imageDataList.push({
          imageSubDir: 'original',
          cleanedDataURL: this.cleanDataURL(originalCanvas),
        });
        if (this.showPreviews) {
          previews.original = originalCanvas;
        }
      }
      this.imageDataList = imageDataList;
      if (Object.keys(previews).length) {
        const imagePreviewsObject = this.buildImageStorageObject({
          isFromObject: true,
          imagePathsList: previews,
          uploadConfig: this.uploadConfig,
          storeOriginal: this.storeOriginal,
        });
        imagePreviewsObject.isPreview = true;
        this.$emit('update:imageUrl', imagePreviewsObject);
      }
    },
    async handleImageStorage() {
      if (this.uploadConfig && Object.keys(this.uploadConfig)?.length) {
        let s3ImagePaths;
        if (this.imageDataList.length && this.s3Folder && this.s3SubDir) {
          try {
            s3ImagePaths = await Promise.all(
              this.buildImageStoragePromises({
                imageDataList: this.imageDataList,
                api: this.api,
                s3Folder: this.s3Folder,
                s3SubDir: this.s3SubDir,
              }),
            );
          } catch (err) {
            this.$toast('could not store image');
            throw new Error(err);
          }
        }
        if (s3ImagePaths?.length) {
          const apiImageObject = this.buildImageStorageObject({
            isFromS3: true,
            imagePathsList: s3ImagePaths,
            uploadConfig: this.uploadConfig,
            storeOriginal: this.storeOriginal,
          });
          this.$emit('update:imageUrl', apiImageObject);
        } else {
          this.$toast('could not store image');
        }
      } else {
        /* TODO in dedicated PR:
            - store 1200x800 for all hero images (brand/site)
            - use helper.buildImageStoragePromises function
            - use this component for brand level storage and for all places where croppa is being used directly
            - add all necessary props
            - use brand and site ids for file_name
          */
        const srcBase64 = this.mycroppa.generateDataUrl(this.imageType);
        const file_name = this.mycroppa
          .getChosenFile()
          .name.split('.')[0]
          .replace(/\W+/g, '');
        const { data } = await this.api.post('/file', {
          file_base64: this.cleanDataURL(srcBase64),
          file_name: this.s3Folder ? `${this.s3Folder}/${file_name}/src:` : file_name,
        });
        this.$emit('update:imageUrl', data.url);
      }
    },
  },
  computed: {
    imageUrlProxy: {
      get() {
        return this.imageUrl || '';
      },
    },
    imageType() {
      return `image/${this.imageTypeSaved}`;
    },
    originalImage() {
      return this.mycroppa.originalImage;
    },
  },
};
</script>

<style scoped>
.disabled {
  background-color: #ffffff;
}
</style>

<style>
input[type='time'] {
  cursor: text;
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("croppa", {
    ref: "mycroppa",
    style: { display: _vm.customControls ? "none" : "" },
    attrs: {
      width: _vm.width,
      height: _vm.height,
      quality: _vm.quality,
      "prevent-white-space": true,
      placeholder: _vm.placeholder,
      "placeholder-font-size": _vm.placeholderFontSize,
      "initial-image": _vm.customControls ? "" : _vm.imageUrlProxy,
      "show-remove-button": _vm.showRemoveButton,
      disabled: _vm.disabled,
      accept: _vm.accept,
      "disable-drag-to-move": _vm.disableCropping,
      "disable-scroll-to-zoom": _vm.disableCropping,
      "disable-pinch-to-zoom": _vm.disableCropping,
      "remove-button-color": "black"
    },
    on: {
      "file-type-mismatch": _vm.onFileTypeMismatch,
      "file-choose": _vm.handleImageChanged,
      move: _vm.handleImageChanged,
      zoom: _vm.handleImageChanged,
      "image-remove": _vm.handleImageChanged
    },
    model: {
      value: _vm.mycroppa,
      callback: function($$v) {
        _vm.mycroppa = $$v
      },
      expression: "mycroppa"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }